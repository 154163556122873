<template>
  <v-card>
    <v-card-title>
      {{ $t('numbers') }}
      <v-spacer></v-spacer>
    </v-card-title>

    <v-data-table
      ref="table"
      v-model="selected"
      :headers="tableHeaders"
      :items="numbers"
      :header-props.sync="headerProps"
      :options.sync="options"
      :server-items-length="numbersTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, 500],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :table="$refs.table" :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>

      <template v-slot:item.name="{ item }">
        <v-chip>
          <v-text-field
            :value="item.name"
            class="centered-input"
          >
          </v-text-field>
        </v-chip>
      </template>
      <template v-slot:item.locked_cause="{ item }">
        <v-chip v-if = 'item.locked'>
             {{item.locked_cause}}
        </v-chip>
        <v-chip v-else = 'item.locked'>
             لايوجد
        </v-chip>
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip v-if="item.type===1" small>
          طالب
        </v-chip>
        <v-chip v-if="item.type===2" small>
          استاذ
        </v-chip>
        <v-chip v-if="item.type===3" small>
          سائق
        </v-chip>
      </template>

      <template v-slot:item.locked="{ item }">
        <v-btn
          x-small
          :color="item.locked ? 'error' : 'success'"
        >
          {{ item.locked ? 'مرفوض' : 'غير مرفوض ' }}
          <v-icon
            dark
            right
          >
            {{ item.locked ? 'mdi-lock' : 'mdi-lock-open' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.active="{ item }">
        <v-btn
          x-small
          :color="item.active ? 'success' : 'error'"
        >
          {{ item.active ? 'مفعل' : ' غير مفعل ' }}
        </v-btn>
      </template>

      <template v-slot:item.number="{ item }">
        {{ item.number ? item.number : '' }}
      </template>

      <template v-slot:item.step_date="{item}">
        {{ item.step_date | format_date }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import { } from '@mdi/js'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import statistics from '@/store/statistics'

export default {
  name: 'Parents',
  components: { DatatableHeader },
  data: () => ({
    translationKey: 'pages.students',
    filters: false,
    numbers: [],
    selected: [],
    headerProps: {},
    numbersTotal: 0,
    loading: true,
    flag: '',
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'phone',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'status',
        align: 'center',
        sortable: false,
        value: 'active',
        sort: true,
        filter: {
          field: 'active',
          menu: false,
          type: 'switch',
        },
      },

      {
        text: 'governorate',
        align: 'center',
        sortable: false,
        value: 'governorate',
        sort: true,
        filter: {
          field: 'governorate',
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'type',
        align: 'center',
        sortable: false,
        value: 'type',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'locked',
        align: 'center',
        sortable: false,
        value: 'locked',
        sort: true,

      },
      {
        text: 'locked_cause',
        align: 'center',
        sortable: false,
        value: 'locked_cause',
        sort: true,

      },
      {
        text: 'step_date',
        align: 'center',
        sortable: false,
        value: 'step_date',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
    ],
  }),
  computed: {
    type() {
      return this.$route.meta.type;
    },
    parentId() {
      return this.$route.params.id;
    },
    tableHeaders() {
      return this.headers.filter(header => this.type === 'active' || header.value !== 'step_date')
    },
  },

  beforeMount() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async exportExcel() {
      axios
        .get('phoneNumber/export', {
          responseType: 'arraybuffer',
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
    },

    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options }).catch(() => {});
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const conditions = {
        ...this.options,
        filters,
      }

      // eslint-disable-next-line default-case
      switch (this.type) {
        case 'sentNumbers': conditions.sent = 1; break
        case 'unsentNumbers': conditions.sent = 0; break
        case 'activeNumbers': conditions.active = 1; break
        case 'inactiveNumbers': conditions.active = 0; conditions.locked = 0; conditions.sent = 1; break
        case 'lockedNumbers': conditions.locked = 1; break
        case 'unlockedNumbers': conditions.locked = 0; break
      }

      if (this.parentId) {
        conditions.parent_id = this.parentId;
      }
      const response = await axios.get(`/phoneNumber?${ObjectToQuery(conditions)}`);
      this.numbers = response.data.data.items
      this.numbersTotal = response.data.data.meta.total

      this.loading = false
      await statistics.dispatch('fetchApplicationStatistics');
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
