var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('numbers'))+" "),_c('v-spacer')],1),_c('v-data-table',{ref:"table",attrs:{"headers":_vm.tableHeaders,"items":_vm.numbers,"header-props":_vm.headerProps,"options":_vm.options,"server-items-length":_vm.numbersTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100, 500],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:headerProps":function($event){_vm.headerProps=$event},"update:header-props":function($event){_vm.headerProps=$event},"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"table":_vm.$refs.table,"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"header.actions",fn:function(ref){
    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',[_c('v-text-field',{staticClass:"centered-input",attrs:{"value":item.name}})],1)]}},{key:"item.locked_cause",fn:function(ref){
    var item = ref.item;
return [(item.locked)?_c('v-chip',[_vm._v(" "+_vm._s(item.locked_cause)+" ")]):_c('v-chip',[_vm._v(" لايوجد ")])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [(item.type===1)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" طالب ")]):_vm._e(),(item.type===2)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" استاذ ")]):_vm._e(),(item.type===3)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" سائق ")]):_vm._e()]}},{key:"item.locked",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":item.locked ? 'error' : 'success'}},[_vm._v(" "+_vm._s(item.locked ? 'مرفوض' : 'غير مرفوض ')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.locked ? 'mdi-lock' : 'mdi-lock-open')+" ")])],1)]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":item.active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.active ? 'مفعل' : ' غير مفعل ')+" ")])]}},{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.number ? item.number : '')+" ")]}},{key:"item.step_date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.step_date))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }